@mixin vi-media($point) {
  @if $point == xs {
    @media (max-width: 687px) {
      @content;
    }
  } @else if $point == sm {
    @media (min-width: 688px) and (max-width: 1023px) {
      @content;
    }
  } @else if $point == md {
    @media (min-width: 1024px) and (max-width: 1439px) {
      @content;
    }
  } @else if $point == lg {
    @media (min-width: 1440px) and (max-width: 1919px) {
      @content;
    }
  } @else if $point == xl {
    @media (min-width: 1920px) and (max-width: 2559px) {
      @content;
    }
  } @else if $point == xxl {
    @media (min-width: 2560px) {
      @content;
    }
  } @else if $point == lt-sm {
    @media (max-width: 687px) {
      @content;
    }
  } @else if $point == lt-md {
    @media (max-width: 1023px) {
      @content;
    }
  } @else if $point == lt-lg {
    @media (max-width: 1439px) {
      @content;
    }
  } @else if $point == lt-xl {
    @media (max-width: 1919px) {
      @content;
    }
  } @else if $point == lt-xxl {
    @media (max-width: 2559px) {
      @content;
    }
  } @else if $point == gt-xs {
    @media (min-width: 688px) {
      @content;
    }
  } @else if $point == gt-sm {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $point == gt-md {
    @media (min-width: 1440px) {
      @content;
    }
  } @else if $point == gt-lg {
    @media (min-width: 1920px) {
      @content;
    }
  } @else if $point == gt-xl {
    @media (min-width: 2560px) {
      @content;
    }
  }
}

@mixin media-only($point) {
  @if $point == xxlarge {
    @media (min-width: 2560px) {
      @content;
    }
  } @else if $point == xlarge {
    @media (min-width: 1920px) and (max-width: 2560px) {
      @content;
    }
  } @else if $point == large {
    @media (min-width: 1440px) and (max-width: 1920px) {
      @content;
    }
  } @else if $point == medium {
    @media (min-width: 1024px) and (max-width: 1440px) {
      @content;
    }
  } @else if $point == small {
    @media (min-width: 687px) and (max-width: 1024px) {
      @content;
    }
  } @else if $point == xsmall {
    @media (max-width: 687px) {
      @content;
    }
  }
}

@mixin media-down($point) {
  @if $point == xxlarge {
    @media (max-width: 2560px) {
      @content;
    }
  } @else if $point == xlarge {
    @media (max-width: 1920px) {
      @content;
    }
  } @else if $point == large {
    @media (max-width: 1440px) {
      @content;
    }
  } @else if $point == medium {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point == small {
    @media (max-width: 688px) {
      @content;
    }
  } @else if $point == xsmall {
    @media (max-width: 375px) {
      @content;
    }
  }
}

@mixin media-up($point) {
  @if $point == xxlarge {
    @media (min-width: 2560px) {
      @content;
    }
  } @else if $point == xlarge {
    @media (min-width: 1920px) {
      @content;
    }
  } @else if $point == large {
    @media (min-width: 1440px) {
      @content;
    }
  } @else if $point == medium {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $point == small {
    @media (min-width: 688px) {
      @content;
    }
  } @else if $point == xsmall {
    @media (min-width: 375px) {
      @content;
    }
  }
}

@mixin height-down($height) {
  @media (max-height: $height) {
    @content;
  }
}

@mixin width-down($width) {
  @media (max-width: $width) {
    @content;
  }
}

@mixin mobile-landscape() {
  @media (max-width: 767px) and (max-height: 420px) {
    @content;
  }
}

@mixin mobile-portrait() {
  @media (max-width: 420px) and (max-height: 767px) {
    @content;
  }
}

@mixin default-section() {
  max-width: 1662px;
  margin: 0 auto;
  @include vi-media(lt-xl) {
    max-width: 1312px;
  }
  @include vi-media(lt-lg) {
    max-width: 944px;
  }
  @include vi-media(lt-md) {
    max-width: 608px;
  }
  @include vi-media(lt-sm) {
    margin: 0 auto;
    max-width: 95vw;
  }
}

@function resize($size, $baseDimension: 1600) {
  $scaleRatio: 100vw / $baseDimension / 1px;
  @return $size * $scaleRatio;
}
