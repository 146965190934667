$vimove-base-black: #000000;
$vimove-base-white: #ffffff;

$vimove-red-palette: (
  30: #fffaf9,
  40: #fff5f3,
  50: #ffece8,
  60: #ffd8d1,
  70: #ffb2a2,
  80: #ff8b74,
  90: #ff6545,
  100: #ff3e17,
  110: #cf3415,
  120: #9e2a13,
  130: #6f1300,
  140: #380900,
  contrast: (
    30: $vimove-base-black,
    40: $vimove-base-black,
    50: $vimove-base-black,
    60: $vimove-base-black,
    70: $vimove-base-black,
    80: $vimove-base-white,
    90: $vimove-base-white,
    100: $vimove-base-white,
    110: $vimove-base-white,
    120: $vimove-base-white,
    130: $vimove-base-white
  )
);
$vimove-green-palette: (
  20: #eafaed,
  30: #d5f4db,
  40: #c0efca,
  50: #abe9b8,
  60: #96e4a6,
  70: #6bd982,
  80: #56d371,
  90: #41ce5f,
  100: #2cc84d,
  110: #28b445,
  120: #23a03e,
  130: #1f8c36,
  140: #1a782e,
  150: #166427,
  160: #12501f,
  170: #0d3c17,
  180: #09280f,
  contrast: (
    20: $vimove-base-white,
    30: $vimove-base-white,
    40: $vimove-base-white,
    50: $vimove-base-white,
    60: $vimove-base-white,
    70: $vimove-base-white,
    80: $vimove-base-white,
    90: $vimove-base-white,
    100: $vimove-base-white,
    110: $vimove-base-white,
    120: $vimove-base-white,
    130: $vimove-base-white,
    140: $vimove-base-white,
    150: $vimove-base-white,
    160: $vimove-base-white,
    170: $vimove-base-white,
    180: $vimove-base-white
  )
);
$vimove-black-palette: (
  10: #e0e0e0,
  20: #b3b3b3,
  30: #808080,
  40: #4d4d4d,
  50: #262626,
  60: #000000,
  70: #000000,
  80: #000000,
  90: #000000,
  100: #000000,
  A100: #a6a6a6,
  A200: #8c8c8c,
  A400: #737373,
  A700: #666666,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);
$vimove-yellow-palette: (
  100: #fbc640,
  contrast: (
    100: black
  )
);
$vimove-base-palette: (
  10: #fafafa,
  20: #f6f6f6,
  30: #f0f0f0,
  40: #dedede,
  50: #d0d0d0,
  60: #858585,
  70: #545454,
  80: #404040,
  90: #353535,
  100: #242424,
  contrast: (
    10: $vimove-base-black,
    20: $vimove-base-black,
    30: $vimove-base-black,
    40: $vimove-base-black,
    50: $vimove-base-black,
    60: $vimove-base-white,
    70: $vimove-base-white,
    80: $vimove-base-white,
    90: $vimove-base-white,
    100: $vimove-base-white
  )
);

$vimove-toast-palette: (
  100: #969aa5,
  contrast: (
    100: $vimove-base-black
  )
);
