@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import 'common';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'theme';
@import 'button';

html,
body {
  height: 100%;
  margin: 0;
  min-width: 272px;
  font-family: Inter, serif;
}

.desktop-wrapper {
  display: block;
  @include vi-media(xs) {
    display: none !important;
  }
}

.mobile-wrapper {
  display: block;
  @include vi-media(gt-xs) {
    display: none !important;
  }
}

.avatar-button {
  width: 24px;
  height: 24px;
  border: solid 1px mat.get-color-config($vimove-base-white);
  background-color: mat.get-color-from-palette($vimove-base-palette, 30);
  border-radius: 100%;
  text-align: center;

  span {
    height: 14px;
    font-family: Viessmann, serif;
    font-size: 10px;
    line-height: 1.4;
    display: block;
    text-align: center;
    width: 100%;
    position: relative;
    top: 5px;
    color: mat.get-color-config($vimove-base-black);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.mat-mdc-snack-bar-container.snack-bar-error {
  background-color: mat.get-color-from-palette($vimove-red-palette, 110);
  margin-bottom: 180px;
  font-size: 18px;
  font-family: Inter, serif;
  text-align: center;
}

.cdk-global-overlay-wrapper {
  background: rgba(0, 0, 0, 0);
}

input {
  caret-color: $vimove-base-black !important;
}

.menu-dark {
  .mat-mdc-menu-content {
    background-color: $vimove-base-black;

    .mat-mdc-menu-item {

      .mat-icon {
        filter: invert(100%) sepia(0%) saturate(7443%) hue-rotate(48deg) brightness(111%) contrast(103%);
      }

      .mdc-list-item__primary-text {
        color: $vimove-base-white;
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
      }

      &:hover {
        .mat-icon {
          filter: invert(86%) sepia(25%) saturate(534%) hue-rotate(73deg) brightness(94%) contrast(90%);
        }

        .mdc-list-item__primary-text {
          color: mat.get-color-from-palette($vimove-green-palette, 60);
        }
      }
    }

  }
}
