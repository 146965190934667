@use '@angular/material' as mat;
@import 'common';

@mixin font-inter-16 {
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}

@mixin vim-buttons-theming {
  &.primary-medium,
  &.secondary-medium,
  &.tertiary-medium {
    height: 42px;
    line-height: 1;
    padding: 3px 24px;
  }

  &.primary-small,
  &.secondary-small,
  &.tertiary-small {
    height: 32px;
    line-height: 1;
    padding: 3px 24px;
  }
  &.primary-extra-small,
  &.secondary-extra-small,
  &.tertiary-extra-small {
    width: auto;
    min-width: initial;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    padding: 0 6px;
    display: flex;
    align-items: center;
  }
  &.mat-black {
    background-color: mat.get-color-from-palette($vimove-black-palette, 100);
    color: mat.get-contrast-color-from-palette($vimove-black-palette, 300);
  }

  &.mat-primary,
  &.mat-primary-medium,
  &.mat-primary-small,
  &.mat-primary-extra-small {
    background-color: $vimove-base-black;
    border-color: $vimove-base-black;
    color: $vimove-base-white;

    .mat-icon {
      filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(49%) hue-rotate(350deg) brightness(115%) contrast(100%);
    }

    &:hover {
      background-color: mat.get-color-from-palette($vimove-base-palette, 90);
      border-color: mat.get-color-from-palette($vimove-base-palette, 90);
      color: $vimove-base-white;

      mat-icon {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(110deg) brightness(110%) contrast(101%);
      }
    }

    &:active:not([disabled]) {
      background-color: mat.get-color-from-palette($vimove-base-palette, 80);
      border-color: mat.get-color-from-palette($vimove-base-palette, 80);
    }

    &[disabled],
    &[disabled]:hover {
      color: mat.get-color-from-palette($vimove-base-palette, 60);
      border-color: mat.get-color-from-palette($vimove-base-palette, 30);
      background-color: mat.get-color-from-palette($vimove-base-palette, 30);

      .mat-icon {
        filter: invert(49%) sepia(0%) saturate(1410%) hue-rotate(135deg) brightness(108%) contrast(87%);
      }
    }
  }

  &.mat-primary-cta,
  &.mat-primary-medium-cta,
  &.mat-primary-small-cta {
    background-color: mat.get-color-from-palette($vimove-red-palette, 100);
    border-color: mat.get-color-from-palette($vimove-red-palette, 100);
    color: $vimove-base-white;

    .mat-icon {
      filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(49%) hue-rotate(350deg) brightness(115%) contrast(100%);
    }

    &:hover {
      background-color: mat.get-color-from-palette($vimove-red-palette, 110);
      border-color: mat.get-color-from-palette($vimove-red-palette, 110);
      color: $vimove-base-white;

      mat-icon {
        filter: invert(57%) sepia(55%) saturate(4402%) hue-rotate(333deg) brightness(104%) contrast(101%);
      }
    }

    &:active:not([disabled]) {
      border-color: mat.get-color-from-palette($vimove-red-palette, 90);
      background-color: mat.get-color-from-palette($vimove-red-palette, 90);
    }

    &[disabled],
    &[disabled]:hover {
      border-color: mat.get-color-from-palette($vimove-red-palette, 70);
      background-color: mat.get-color-from-palette($vimove-red-palette, 70);
    }
  }

  &.mat-secondary,
  &.mat-secondary-medium,
  &.mat-secondary-small,
  &.mat-secondary-extra-small {
    background-color: transparent;
    color: $vimove-base-black;
    border: 2px solid mat.get-color-from-palette($vimove-base-palette, 40);

    .mat-icon {
      filter: brightness(0) saturate(100%) invert(0%) sepia(3%) saturate(7455%) hue-rotate(17deg) brightness(96%) contrast(95%);
    }

    &:hover {
      background-color: $vimove-base-black;
      border-color: $vimove-base-black;
      color: $vimove-base-white;

      .mat-icon {
        filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7500%) hue-rotate(277deg) brightness(96%) contrast(104%);
      }
    }

    &:active:not([disabled]) {
      background-color: mat.get-color-from-palette($vimove-base-palette, 80);
      border-color: mat.get-color-from-palette($vimove-base-palette, 80);
    }

    &[disabled],
    &[disabled]:hover {
      border-color: mat.get-color-from-palette($vimove-base-palette, 50);
      background-color: transparent;
      color: mat.get-color-from-palette($vimove-base-palette, 50);
    }
  }

  &.mat-secondary-dark,
  &.mat-secondary-dark-medium,
  &.mat-secondary-dark-small,
  &.mat-secondary-dark-extra-small {
    background-color: transparent;
    color: mat.get-color-from-palette($vimove-base-palette, 20);
    border: 2px solid mat.get-color-from-palette($vimove-base-palette, 80);

    .mat-icon {
      filter: invert(90%) sepia(37%) saturate(2%) hue-rotate(155deg) brightness(107%) contrast(93%);
    }

    &:hover {
      background-color: mat.get-color-from-palette($vimove-base-palette, 20);
      border-color: mat.get-color-from-palette($vimove-base-palette, 20);
      color: $vimove-base-black;

      .mat-icon {
        filter: invert(0%) sepia(83%) saturate(7432%) hue-rotate(209deg) brightness(88%) contrast(115%);
      }
    }

    &:active:not([disabled]) {
      background-color: mat.get-color-from-palette($vimove-base-palette, 60);
      border-color: mat.get-color-from-palette($vimove-base-palette, 60);
    }

    &[disabled],
    &[disabled]:hover {
      border-color: mat.get-color-from-palette($vimove-base-palette, 90);
      color: mat.get-color-from-palette($vimove-base-palette, 90);
      background-color: transparent;

      .mat-icon {
        filter: invert(19%) sepia(3%) saturate(57%) hue-rotate(59deg) brightness(90%) contrast(90%);
      }
    }
  }

  &.mat-tertiary,
  &.mat-tertiary-medium,
  &.mat-tertiary-small {
    border: solid 2px mat.get-color-from-palette($vimove-red-palette, 100);
    background-color: transparent;
    color: mat.get-color-from-palette($vimove-red-palette, 100);

    mat-icon {
      filter: brightness(0) saturate(100%) invert(66%) sepia(90%) saturate(6911%) hue-rotate(351deg) brightness(100%) contrast(103%);
    }

    &:hover {
      background-color: mat.get-color-from-palette($vimove-red-palette, 100);
      color: $vimove-base-white;

      mat-icon {
        filter: invert(34%) sepia(48%) saturate(3922%) hue-rotate(349deg) brightness(99%) contrast(108%);
      }
    }

    [disabled] {
      background-color: mat.get-color-from-palette($vimove-base-palette, 50);
      color: $vimove-base-white;
      border-color: transparent;

      mat-icon {
        filter: brightness(0) saturate(100%) invert(66%) sepia(90%) saturate(6911%) hue-rotate(351deg) brightness(100%) contrast(103%);
      }
    }

    &:active:not([disabled]) {
      background-color: mat.get-color-from-palette($vimove-base-palette, 20);
      color: mat.get-color-from-palette($vimove-black-palette, 100);
    }

    &.mat-mdc-raised-button {
      color: mat.get-color-from-palette($vimove-black-palette, 100);
      border-color: mat.get-color-from-palette($vimove-black-palette, 100);
    }

    &.mat-mdc-outlined-button {
      background-color: $vimove-base-white;
      border: solid 2px mat.get-color-from-palette($vimove-base-palette, 40);
      color: $vimove-base-black;
    }
  }

  &.tertiary-extra-small {
    width: auto;
    background-color: $vimove-base-white;
    color: mat.get-color-from-palette($vimove-red-palette, 110);
    border: 2px solid $vimove-base-white;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    padding: 0 6px;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;

    mat-icon {
      width: 14px;
      height: 14px;
      line-height: 1;
      margin-right: 6px;
      filter: invert(27%) sepia(67%) saturate(2187%) hue-rotate(352deg) brightness(94%) contrast(95%);
    }

    &:hover {
      background-color: mat.get-color-from-palette($vimove-red-palette, 110);
      color: $vimove-base-white;
      border: 2px solid mat.get-color-from-palette($vimove-red-palette, 110);

      mat-icon {
        filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(229deg) brightness(102%) contrast(101%);
      }
    }
  }
}

.vim-button {
  &.mat-mdc-button,
  &.mat-mdc-outlined-button,
  &.mat-mdc-list-item {
    @include vim-buttons-theming;
    @include font-inter-16;

    border: 2px solid transparent;
    border-radius: 100px;
    cursor: pointer;
    padding: 12px 24px;

    .mdc-button__label {
      display: flex;
      align-items: center;
    }

    @include vi-media(gt-sm) {
      height: 56px;
    }

    @include vi-media(lt-md) {
      height: 48px;
      align-self: stretch;
      padding: 9px 24px;
    }

    &.mat-secondary {
      @include font-inter-16;
      color: black;
    }
  }
}
