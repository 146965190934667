@use '@angular/material' as mat;
@import 'common';

$vimove-primary: mat.define-palette(mat.$grey-palette, 900);
$vimove-accent: mat.define-palette(map-merge(mat.$grey-palette, $vimove-green-palette), 100);
$vimove-warn: mat.define-palette(map-merge(mat.$grey-palette, $vimove-red-palette), 110);
$vimove-yellow: mat.define-palette(map-merge(mat.$grey-palette, $vimove-yellow-palette), 100);
$vimove-base: mat.define-palette(map-merge(mat.$grey-palette, $vimove-base-palette), 30);
$vimove-toast: mat.define-palette(map-merge(mat.$grey-palette, $vimove-toast-palette), 100);
$vimove-secondary: mat.define-palette(map-merge(mat.$grey-palette, $vimove-black-palette), 100);
$vimove-tertiary: mat.define-palette(map-merge(mat.$grey-palette, $vimove-green-palette), 150);
$vimove-quaternary: mat.define-palette(map-merge(mat.$grey-palette, $vimove-green-palette), 150);

$my-typography: mat.define-typography-config(
);

$vimove-app-theme: mat.define-light-theme(
    (
      color: (
        primary: $vimove-primary,
        accent: $vimove-accent,
        warn: $vimove-warn,
        secondary: $vimove-secondary,
        tertiary: $vimove-tertiary,
        quaternary: $vimove-quaternary
      ),
      typography: $my-typography,
    )
);

/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated */
@include mat.all-legacy-component-themes($vimove-app-theme);

@include mat.all-component-themes($vimove-app-theme);

.mat-mdc-snack-bar-container {
  border-radius: 0;
  min-height: 64px;
  max-width: initial;
  display: flex;
  align-items: center;
  padding-left: 19px;
  padding-right: 19px;
  box-shadow: none;

  > div {
    width: 100%;
  }


  &.red-snackbar {
    --mdc-snackbar-container-color: #cf3415;
  }

  &.green-snackbar {
    --mdc-snackbar-container-color: #09280f;

    svg {
      path {
        fill: $vimove-base-white;
      }
    }
  }

}

.mat-mdc-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
.mat-form-field.mat-focused .mat-form-field-label {
  color: $vimove-base-black;
}

@mixin base-colors {
  @for $i from 1 through 9 {
    .color-base-#{$i*10} {
      color: mat.get-color-from-palette($vimove-base-palette, $i * 10);
    }
    .background-color-base-#{$i*10} {
      background-color: mat.get-color-from-palette($vimove-base-palette, $i * 10);
    }
  }
}

@include base-colors;
.vim-scrollbar {
  --scrollbar-thumb-color: #242424;
  --scrollbar-track-color: #dedede;
  --scrollbar-size: 8px;
  --scrollbar-border-radius: 0;
}
